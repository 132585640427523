<template>
  <div class="teachingPatro">
    <div class="flex-box">
      <div class="left">
        学期时间：{{ semesterTime }}
        <!-- 年09月-2021年2月 -->
      </div>
      <div class="flex-box">
        <el-date-picker v-model="queryDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
        </el-date-picker>
        <el-button
          type="primary"
          class="picker"
          @click="loadFestival()"
          >查 询</el-button
        >
      </div>
    </div>

    <!-- 内容部分 -->
    <div class="content flex-box">
      <div class="contentLeft">
        <div
          v-for="(item, index) in festivalList"
          :key="index"
          class="contentLeftBox"
        >
          <div class="contentLeftTitle">{{ item.startYearMonth }}</div>
          <div class="contentLeftContent">
            <div v-for="(jtem, index2) in item.children" :key="index2">
              <span>{{ jtem.day }} : <span>{{ jtem.content }}</span></span>
              <span class="iconStyle">
                <el-link
                  @click="editDate(item, index, jtem, index2)"
                  type="primary"
                  icon="el-icon-edit"
                  style="margin-left: 15px"
                ></el-link>
                <el-link
                  @click="deleteDate(jtem.id)"
                  type="danger"
                  icon="el-icon-delete"
                  style="margin-left: 15px"
                ></el-link>
              </span>
            </div>
          </div>
        </div>

      </div>
      <div class="contentRight">
        <div class="contentRightDiv">
          <div class="contentRightDivTitle">
            <!-- 上学期 -->
            <div v-if="flag">
              <i
                v-if="!nextPageVisible"
                @click="loadDate(1)"
                class="el-icon-arrow-left iMargin"
              ></i>
            </div>
            <span style="font-weight: bold; font-size: 20px">{{ year }}年</span>
            <!-- 上学期 -->
            <div v-if="flag">
              <i
                v-if="!nextPageVisible"
                @click="loadDate(2)"
                class="el-icon-arrow-right iMargin"
              ></i>
            </div>
          </div>

          <!-- <div class="contentRightDivContent">
            <div class="calendarBox">
              <table
                v-for="(item, index) in calendarList"
                :key="index"
                class="calendarTable"
              >
                <div style="font-weight:bold;font-size:18px;" class="calendarMonth">{{ item.month }}月</div>
                <div class="dayBox">
                  <div class="dayTitle">
                    <div>日</div>
                    <div>一</div>
                    <div>二</div>
                    <div>三</div>
                    <div>四</div>
                    <div>五</div>
                    <div>六</div>
                  </div>
                  <div class="dayContent">
                    <div
                      :class="dayClass(i,index)"
                      @click="dateClick(m, index, i)"
                      v-for="(m, i) in item.day"
                      :key="i">
                      {{ m }}
                    </div>

                  </div>
                </div>
              </table>
            </div>

            <div v-if="buttonVisible" class="buttonDiv">
              <el-button
                v-if="nextPageVisible"
                @click="lastOrNextPageClick(4)"
                type="text"
                >下一页</el-button
              >
              <el-button
                v-if="!nextPageVisible"
                @click="lastOrNextPageClick(3)"
                type="text"
                >上一页</el-button
              >
            </div>
          </div> -->

          <div class="contentRightDivContent">
            <div class="calendarBox">
              <table
                v-for="(item, index) in calendarList"
                :key="index"
                class="calendarTable"
              >
                <div style="font-weight:bold;font-size:18px;" class="calendarMonth">{{ item.month }}月</div>
                <div class="dayBox">
                  <div class="dayTitle">
                    <div>日</div>
                    <div>一</div>
                    <div>二</div>
                    <div>三</div>
                    <div>四</div>
                    <div>五</div>
                    <div>六</div>
                  </div>
                  <div class="dayContent">
                    <div
                      :style="m.isHoliday ? m.color : ''"
                      :class="dayClass(i,index)"
                      @click="dateClick(m.k, index, i)"
                      v-for="(m, i) in item.day"
                      :key="i">
                      {{ m.k }}
                    </div>

                  </div>
                </div>
              </table>
            </div>

            <!-- 下一页与上一页 -->
            <div v-if="buttonVisible" class="buttonDiv">
              <el-button
                v-if="nextPageVisible"
                @click="lastOrNextPageClick(4)"
                type="text"
                >下一页</el-button
              >
              <el-button
                v-if="!nextPageVisible"
                @click="lastOrNextPageClick(3)"
                type="text"
                >上一页</el-button
              >
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- 编辑节日 -->
    <el-dialog title="编辑节日" :visible.sync="dateDialogVisible" width="30%">
      <div class="center">
        <el-form
          :model="editDateForm"
          :rules="editDateFormRules"
          ref="editDateFormRef"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="开始日期：" prop="begin_date">
            <el-date-picker
              v-model="editDateForm.begin_date"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="请选择开始日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束日期：" prop="end_date">
            <el-date-picker
              v-model="editDateForm.end_date"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="请选择结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="节日名称" prop="content">
            <el-input v-model="editDateForm.content"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDateFormConfirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 弹出年份选择对话框 -->
    <el-dialog
      custom-class="dialogStyle"
      :visible.sync="yearDialogVisible"
      width="30%"
      :show-close="false"
    >
      <div class="dialogYearTitle">
        <i @click="arrowClick(1)" class="el-icon-arrow-left iHover"></i>
        <span>{{ yearForm.startYear }} 年 - {{ yearForm.endYear }}年</span>
        <i @click="arrowClick(2)" class="el-icon-arrow-right iHover"></i>
      </div>
      <div class="dialogYearContent">
        <div class="dialogYearContentDiv">
          <el-button
            class="textColor"
            :disabled="
              (fixStartYear != item && fixEndYear != item) ||
              fixStartYear == fixEndYear
            "
            @click="selectYear(item, index)"
            v-for="(item, index) in yearList"
            :key="index"
            :class="year == item ? 'yearColor' : ''"
            type="text"
            >{{ item }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "teachingPatro",
  data() {
    return {
      page: { currentPage: 0, pageCount: 1 },
      // 日期查询
      value1: "",
      // 年份对话框显示与隐藏
      yearDialogVisible: false,
      // 年份表单
      yearForm: {
        startYear: 2020,
        endYear: 2029,
      },
      // 年份数组
      yearList: [],
      // 当前学期起止时间
      semesterTime: "",
      // 年份展示
      year: -1,
      // 月
      month: -1,
      // 天
      day: [],
      // 日历列表
      calendarList: [],
      // 天的数组
      dayList: [],
      // 结束年
      endYear: -1,
      // 结束月
      endMonth: -1,
      fixStartYear: -1,
      fixEndYear: -1,
      fixStartMonth: -1,
      fixEndMonth: -1,
      // 下一页按钮显示或隐藏
      nextPageVisible: false,
      buttonVisible: "",
      flag: "",
      // 点击的开始时间
      clickStartTime: "",
      // 点击的结束时间
      clickEndTime: "",
      // 选中的年的数组
      selectYearList: [],
      // 选中的月的数组
      selectMonthList: [],
      // 选中的天的数组
      timeList: [],
      // 时间对象
      timeObj: {
        clickStartTime: "",
        clickEndTime: "",
      },
      // 开始日的索引
      startDayIndex: -1,
      // 结束日的索引
      endDayIndex: -1,
      // table的索引
      tableIndex: -1,
      // 节日列表
      festivalList: [],
      count: 0,
      // 节日列表
      dateList: [],
      //是节日的日期列表['2021-01-01','2021-05-01']
      isHolidayList:[],
      // 编辑节日对话框显示与隐藏
      dateDialogVisible: false,
      // 编辑节日的表单对象
      editDateForm: {
        id: "",
        begin_date: "",
        end_date: "",
        content: "",
      },
      queryDate:'',
      query:{
          year:'',
          month:'',
          day:''
      },
      // 编辑节日的表单验证规则对象
      editDateFormRules: {
        begin_date: [
          { required: true, message: "请选择开始日期", trigger: "blur" },
        ],
        end_date: [
          { required: true, message: "请选择结束日期", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入节日名称", trigger: "blur" },
        ],
      },
      nextPageHidden:'',
      // 颜色数组
      colorList:[],
      festivalList2:[],
      type:''
    };
  },
  created() {
    let nowSemester = sessionStorage.getItem("nowSemester");
    let semesterList = JSON.parse(sessionStorage.getItem("semesterList"));
    semesterList.forEach((item) => {
      if (item.id == nowSemester) {
        let startYear = item.begindate.substr(0, 4);
        let startMonth = item.begindate.substr(5, 2);
        let startDay = item.begindate.substr(8, 2);
        let endYear = item.enddate.substr(0, 4);
        let endMonth = item.enddate.substr(5, 2);
        let endDay = item.enddate.substr(8, 2);
        this.semesterTime = `${startYear}年${startMonth}月${startDay}日 - ${endYear}年${endMonth}月${endDay}日`;
        // 可变的
        this.year = startYear;
        this.month = startMonth;
        this.endYear = endYear;
        this.endMonth = endMonth;
        // 初始年月
        this.fixStartYear = startYear;
        this.fixEndYear = endYear;
        this.fixStartMonth = startMonth;
        this.fixEndMonth = endMonth;
      }
    });
    // 加载日期
    if (Number(this.fixEndYear) - Number(this.fixStartYear) > 0) {
      this.nextPageVisible = false;
      this.flag = true;
      // 上学期
      this.loadDate(1);
    } else {
      this.buttonVisible = true;
      this.nextPageVisible = true;
      this.flag = false;
      // 下学期
      this.loadDate(3);
    }

    // 加载节日
    this.loadFestival();
    // 右边节日显示颜色
    // this.loadRightFestivalColor()
  },
  computed:{
    
  },
  methods: {
    //   日期颜色
    dateColor(){
      console.log()
      var r = Math.floor(Math.random()*256);
			var g = Math.floor(Math.random()*256);
			var b = Math.floor(Math.random()*256);
			var color = '#'+r.toString(16)+g.toString(16)+b.toString(16);
      // this.colorList.push(color)
			// return `color:${color}`;
			// return `background-color:${color}`;
			return `color:red`;
    },
    dayClass(dayIndex,monthIndex){
      let className = [dayIndex >= this.startDayIndex && dayIndex <= this.endDayIndex && this.tableIndex == monthIndex ? 'dateTextColor'  : '', (dayIndex==this.startDayIndex) && this.tableIndex == monthIndex ? 'startStyle' : '', (dayIndex==this.endDayIndex) && this.tableIndex == monthIndex ? 'endStyle' : '']

      return className

    },
    loadRightFestivalColor(){
      console.log(this.festivalList2)
      console.log(this.calendarList)
      
      let arr = []
      this.festivalList2.forEach(item=>{
        let obj = {
          color:item.color,
          startTime:item.begin_date,
          timeLength:(new Date(item.end_date).getTime() - new Date(item.begin_date).getTime() + 86400000) / 86400000
        }
        arr.push(obj)
      })

      console.log(arr)

      arr.forEach(item=>{
        this.calendarList.forEach((jtem,indexj)=>{
          let year = jtem.year
          let month = jtem.month < 10 ? '0' + jtem.month : String(jtem.month)
          jtem.day.forEach((mtem,indexm,arr)=>{
            if(mtem){
              let day = mtem.k < 10 ? '0' + mtem.k : String(mtem.k)
              let theDay = `${year}-${month}-${day}`
              if(item.startTime == theDay){
                mtem.isHoliday = true
                mtem.color = item.color
                mtem.timeLength = item.timeLength
                for(let i = indexm; i < indexm + item.timeLength; i++){
                  this.calendarList[indexj].day[i].isHoliday = true
                  this.calendarList[indexj].day[i].color = this.dateColor()
                  
                }
              }
            }
          })
        })
      })
      console.log(this.calendarList)

    },
    // 加载节日(左边数据渲染)
    loadFestival() {

        console.log(this.queryDate)
        let data = {
            year:this.queryDate.substr(0,4),
            month:this.queryDate.substr(5,2),
            day:this.queryDate.substr(8,2)
        }
        console.log(data)
      this.$post(`/media/querySchoolCalendar.do`,data)
      .then((res) => {
        // let yearMonthList = [];
        console.log(res);
        this.festivalList2 = res.data
        // return
        this.festivalList = [];
        res.data.forEach((item) => {
          item.color = this.dateColor()

          let startYearMonth = item.begin_date.substr(0, 7);
          if (
            this.festivalList.findIndex((value, index, arr) => {
              return value.startYearMonth === startYearMonth;
            }) === -1
          ) {
            this.festivalList.push({
              startYearMonth: startYearMonth,
              children: [],
            });
          }

        });
        this.festivalList.forEach((item,index)=>{
            res.data.forEach((festival,key)=>{
                festival.day = festival.begin_date.substr(8, 2) == festival.end_date.substr(8, 2) ? `${festival.begin_date.substr(8, 2)}日` : `${festival.begin_date.substr(8, 2)}日-${festival.end_date.substr(8,2)}日`
                if(festival.begin_date.substr(0, 7)===item.startYearMonth){
                    
                    item.children.push(festival)
                }
            })
        })

        // 右边节日显示颜色
        this.loadRightFestivalColor()
        // console.log(this.festivalList)

      });
    },
    // 年份数据渲染
    loadYearData(type) {
      this.yearDialogVisible = true;
      this.yearList = [];
      let YearLength = this.yearForm.endYear - this.yearForm.startYear + 1;
      let init = this.yearForm.startYear;
      let end = this.yearForm.endYear;

      if (type == 2) {
        // 加年份
        for (var i = 0; i < YearLength; i++) {
          let yearItem = init + i;
          this.yearList.push(yearItem);
        }
        console.log(this.yearList);
      } else if (type == 1) {
        // 减年份
        for (var i = YearLength; i > 0; i--) {
          let yearItem = end - i + 1;
          this.yearList.push(yearItem);
        }
      }
      console.log(this.yearList);
    },
    // 加载日期
    loadDate(type) {
      this.calendarList = [];
      // 学期为上学期
      // 选择开始年
      if (type == 1) {
        this.year =
          Number(this.year) <= Number(this.fixStartYear)
            ? Number(this.fixStartYear)
            : Number(this.year) - 1;

        let startYear = this.fixStartYear;
        let startMonth = this.fixStartMonth;

        let monthLength = this.fixEndMonth - this.fixStartMonth
        // let m1 = monthLength < 0 ? (13 - startMonth) : (monthLength + 1)

        for (let i = 0; i < 13 - startMonth; i++) {
          let strMonth =
            Number(startMonth) + i < 10
              ? "0" + Number(startMonth) + i
              : Number(startMonth) + i + "";
          let day1 = this.loadMonthAndDay(startYear, strMonth);
          this.dayList = [];

          // 得到每年每月1号是星期几
          let year = this.fixStartYear;
          let month = Number(this.fixStartMonth) + i;
          let date = new Date(year, month - 1, 1);
          let dayNum = date.getDay();

          for (let n = 0; n < dayNum; n++) {
            this.dayList.push("");
          }

          // for (let j = 1; j < day1 + 1; j++) {
          //   j = j < 10 ? "0" + j : j;
          //   this.dayList.push(j);
          // }

          for (let j = 1; j < day1 + 1; j++) {
            // j = j < 10 ? "0" + j : j;
            let obj = {
              isHoliday:false,
              k:j
            }

            this.dayList.push(obj);
          }

          // console.log(this.dayList)

          let obj = {
            year: this.year,
            month: Number(startMonth) + i,
            day: this.dayList,
          };
          this.calendarList.push(obj);
        }

      } else if (type == 2) {
        this.year =
          Number(this.year) <= Number(this.fixEndYear)
            ? Number(this.fixStartYear) + 1
            : Number(this.fixEndYear);
        // console.log(Number(this.fixEndYear))
        // 选择结束年
        let endYear = this.fixEndYear;
        let endMonth = this.fixEndMonth;
        for (let m = 1; m < Number(endMonth) + 1; m++) {
          let strMonth = m < 10 ? "0" + m : m + '';
          let day1 = this.loadMonthAndDay(endYear, strMonth);
          this.dayList = [];

          // 得到每年每月1号是星期几
          let year = this.fixEndYear;
          let month = m;
          let date = new Date(year, month - 1, 1);
          let dayNum = date.getDay();
          // console.log(year, month);
          // console.log(this.month);

          for (let n = 0; n < dayNum; n++) {
            this.dayList.push("");
          }

          // for (let j = 1; j < day1 + 1; j++) {
          //   j = j < 10 ? "0" + j : j;
          //   this.dayList.push(j);
          // }

          for (let j = 1; j < day1 + 1; j++) {
            // j = j < 10 ? "0" + j : j;
            let obj = {
              isHoliday:false,
              k:j
            }
            this.dayList.push(obj);
          }

          // console.log(this.dayList)
          let obj = {
            year: this.year,
            month: m,
            day: this.dayList,
          };
          this.calendarList.push(obj);
        }
      } else if (type == 3) {
        this.year =
          Number(this.year) <= Number(this.fixStartYear)
            ? Number(this.fixStartYear)
            : Number(this.year) - 1;
        // this.year =
        // 学期为下学期（3-8）开始页
        // let monthLength = this.fixEndYear - this.fixStartYear;

        let monthLength = this.fixEndMonth - this.fixStartMonth;
        let m1 = monthLength > 3 ? 4 : monthLength + 1
        if(monthLength <= 3){
          this.buttonVisible = false
        }
        for (let s = 0; s < m1; s++) {
          let startYear = this.fixStartYear;
          let startMonth = this.fixStartMonth;
          
          let strMonth =
            Number(startMonth) + s < 10
              ? "0" + (Number(startMonth) + s)
              : Number(startMonth) + s + '';
              
          let day1 = this.loadMonthAndDay(startYear, strMonth);
          // console.log(startYear,strMonth)
          // console.log(day1)
          this.dayList = [];

          // 得到每年每月1号是星期几
          let year = this.fixStartYear;
          let month = Number(this.fixStartMonth) + s;
          let date = new Date(year, month - 1, 1);
          let dayNum = date.getDay();

          for (let n = 0; n < dayNum; n++) {
            this.dayList.push("");
          }

          // for (let k = 1; k < day1 + 1; k++) {
          //   k < 0 ? "0" + k : k;
          //   this.dayList.push(k);
          // }

          for (let k = 1; k < day1 + 1; k++) {
            // k < 0 ? "0" + k : k;
            let obj = {
              isHoliday:false,
              k
            }
            this.dayList.push(obj);
          }

          let obj = {
            year: this.year,
            month: Number(startMonth) + s,
            day: this.dayList,
          };
          this.calendarList.push(obj);
        }

        this.nextPageVisible = true;
      } else if (type == 4) {
        // 学期为下学期（3-8）结束页
        let monthLength = this.fixEndMonth - this.fixStartMonth;
        let endYear = this.fixEndYear;
        // let endMonth = this.fixEndMonth
        // 下一页的开始月份（3+4） (8-3 -4+1)

        for (let a = 0; a < monthLength - 3; a++) {
          // 下一页的月份
          let nextMonths = Number(this.fixStartMonth) + 4 + a;
          let strMonth = nextMonths < 10 ? "0" + nextMonths : nextMonths + '';
          let day1 = this.loadMonthAndDay(endYear, strMonth);
          this.dayList = [];

          // 得到每年每月1号是星期几
          let year = this.fixEndYear;
          let month = Number(this.fixStartMonth) + 4 + a;
          let date = new Date(year, month - 1, 1);
          let dayNum = date.getDay();
          // console.log(year, month);

          for (let n = 0; n < dayNum; n++) {
            this.dayList.push("");
          }

          // for (let h = 1; h < day1 + 1; h++) {
          //   h = h < 10 ? "0" + h : h;
          //   this.dayList.push(h);
          // }

          for (let h = 1; h < day1 + 1; h++) {
            // h = h < 10 ? "0" + h : h;
            let obj = {
              isHoliday:false,
              k:h
            }
            this.dayList.push(obj);
          }

          // console.log(this.dayList);
          let obj = {
            year: this.year,
            month: nextMonths,
            day: this.dayList,
          };
          console.log(obj);
          this.calendarList.push(obj);
        }

        this.nextPageVisible = false;
        // console.log(this.nextPageVisible);
      }

      this.loadFestival()
      this.type = type

      // console.log(this.calendarList)
    },
    getDay() {},
    // 根据年和月得到每月天数
    loadMonthAndDay(year, month) {
      // 月份要传字符串类型
      let dayArr = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (month == "02" || month == "2") {
        dayArr[1] =
          (year % 4 == 0 && year % 100 != 0) || year % 400 == 0 ? 29 : 28;
      }
      let flag = month.substr(0, 1);
      let index;
      if (flag == 0) {
        index = month.substr(1, 1) - 1;
      } else {
        index = month - 1;
      }
      // 返回天数
      return dayArr[index];
    },
    // 点击箭头
    arrowClick(type) {
      this.yearForm.startYear =
        type == 1 ? this.yearForm.startYear - 10 : this.yearForm.startYear + 10;
      this.yearForm.endYear =
        type == 1 ? this.yearForm.endYear - 10 : this.yearForm.endYear + 10;
      this.loadYearData(type);
    },
    // 选择年份
    selectYear(item, index) {
      // console.log(this.year)
      this.year = item;
      let type = item == this.fixStartYear ? 1 : 2;
      console.log(type);
      this.loadDate(type);
      this.yearDialogVisible = false;
    },
    // 点击开始日期和结束日期添加节日
    dateClick(m, index, i) {
      this.tableIndex = index;
      // 把选中的年放在数组里面
      this.selectYearList.push(this.calendarList[index].year);
      // 把选中的月放在数组里面
      this.selectMonthList.push(this.calendarList[index].month);
      // 把选中的天放在数组里面
      this.timeList.push(Number(m));

      if (this.timeList.length == 3) {
        this.selectYearList = [];
        this.selectMonthList = [];
        this.timeList = [];
        this.timeList.push(Number(m));
        this.selectYearList.push(this.calendarList[index].year);
        this.selectMonthList.push(this.calendarList[index].month);
      }
      // console.log(this.timeList)
      if (
        this.timeList.length == 2 &&
        this.timeList[1] - this.timeList[0] >= 0
      ) {
        console.log(this.selectMonthList);
        console.log(this.timeList);

        // 3号-5号（共3天）
        let timeLength = this.timeList[1] - this.timeList[0];
        // 开始日的索引
        this.startDayIndex = i - timeLength;
        // 结束日的索引
        this.endDayIndex = i;
        // console.log(this.startDayIndex,this.endDayIndex)

        // 选中的开始年
        let startYear = this.selectYearList[0];
        // 选中的结束年
        let endYear = this.selectYearList[1];
        // 选中的开始月
        let startMonth =
          this.selectMonthList[0] < 10
            ? "0" + this.selectMonthList[0]
            : this.selectMonthList[0];
        // 选中的结束月
        let endMonth =
          this.selectMonthList[1] < 10
            ? "0" + this.selectMonthList[1]
            : this.selectMonthList[1];
        // 选中的开始日(小于10为字符串类型，大于等于10为数字类型)
        let startDay =
          this.timeList[0] < 10 ? "0" + this.timeList[0] : this.timeList[0];
        // 选中的结束日
        let endDay =
          this.timeList[1] < 10 ? "0" + this.timeList[1] : this.timeList[1];
        // console.log(startDay,endDay)

        let startTime = `${startYear}-${startMonth}-${startDay}`;
        let endTime = `${endYear}-${endMonth}-${endDay}`;
        console.log(startTime, endTime);
        // 弹出弹框
        this.$prompt("", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          center: true,
          title: "",
          inputPattern:/\S/,
          inputErrorMessage:'请输入日期名称',
          inputPlaceholder: "请输入日期名称",
          showClose: false,
          // inputValidator(res){
          //   console.log(res)
          // },
        })
          .then(({ value }) => {
            // 添加节日
            let data = {
              begin_date: startTime,
              end_date: endTime,
              content: value,
            };
            this.$post(`/media/insertSchoolCalendar.do`, data).then((res) => {
              console.log(res);
              this.startDayIndex = -1
              this.endDayIndex = -1
              this.loadFestival();
            });

            this.$message({
              type: "success",
              message: "日期名称是: " + value,
            });
          })
          .catch(() => {
              this.startDayIndex = -1
              this.endDayIndex = -1
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
      }
    },
    // 编辑节日
    editDate(item, index, jtem, index2) {
      this.dateDialogVisible = true;

      // 2020-09-07
      this.editDateForm.begin_date =
        item.startYearMonth + "-" + jtem.day.substr(0, 2);
      this.editDateForm.end_date =
        jtem.day.length == 3
          ? item.startYearMonth + "-" + jtem.day.substr(0, 2)
          : item.startYearMonth + "-" + jtem.day.substr(4, 2);
      this.editDateForm.content = jtem.content;
      this.editDateForm.id = jtem.id;
    },
    // 编辑节日表单提交
    editDateFormConfirm() {
      let data = {
        id: this.editDateForm.id,
        begin_date: this.editDateForm.begin_date,
        end_date: this.editDateForm.end_date,
        content: this.editDateForm.content,
      };
      this.$post(`/media/updateSchoolCalendar.do`, data).then((res) => {
        console.log(res);
        this.dateDialogVisible = false;
        // this.loadFestival();
        this.loadDate(this.type)
      });
    },
    // 删除节日
    deleteDate(id) {
      this.$confirm("是否删除该节日?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post(`/media/deleteSchoolCalendar.do`, { id }).then((res) => {
            console.log(res);
            // this.loadFestival();
            this.loadDate(this.type)
            
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 点击上一页或者下一页
    lastOrNextPageClick(type) {
      this.flag = false;
      this.loadDate(type);
      // this.loadFestival()
    },
  },
};
</script>

<style scoped>
.dateTextColor {
    /* color: red; */
    /* background-color: #F2F6FC; */
    background-color: #00875A;
    color: #fff;
}

.startStyle{
    border-top-left-radius: 50% ;
    border-bottom-left-radius: 50% ;
    /* background-color: #409EFF;
    color: #fff; */
}

.endStyle{
    border-top-right-radius: 50% ;
    border-bottom-right-radius: 50% ;
}

.left {
  font-weight: bold;
  font-size: 18px;
}

.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}

.content {
  margin-top: 14px;
}

.contentLeft {
  width: 30%;
  height: 660px;
  margin-right: 40px;
  border: 1px solid #ccc;
  overflow: auto;
}

.contentLeftBox {
  margin: 10px;
}

.contentLeftTitle {
    font-weight: bold;
}

.contentLeftContent {
  margin-left: 30px;
}

.dateColor{
    color:blue
}

.contentLeftContent .iconStyle {
  display: none;
}

.contentLeftContent:hover .iconStyle {
  display: inline;
}

/* .contentLeftContent span:nth-child(1):hover .iconStyle {
  display: inline;
} */

/* .contentLeft ul{
    margin: 0 auto;
    width: 248px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.contentLeft ul li{
    margin-top: 6px;
    margin-bottom: 6px;
} */

.contentRight {
  width: 70%;
  height: 660px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.contentRightDiv {
  /* background-color: red; */
  margin: 16px;
  margin-top: 8px;
  height: 628px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid #ccc; */
}

.contentRightDivTitle {
  /* background-color: blue; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5%;
  width: 100%;
  padding-bottom: 6px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
}

.contentRightDivTitle .el-icon-arrow-left:hover {
  cursor: pointer;
  color: #00a1de;
}

.contentRightDivTitle .el-icon-arrow-right:hover {
  cursor: pointer;
  color: #00a1de;
}

.contentRightDivTitle > span {
  /* cursor: pointer; */
}

.iMargin {
  margin-left: 8px;
  font-weight: bold;
}

.contentRightDivContent {
  width: 100%;
  height: 95%;
  display: flex;
  /* justify-content: space-around;
    align-items: space-around; */
  flex-wrap: wrap;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonDiv {
}

/* 盒子 */
.calendarBox {
  width: 800px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.calendarTable {
  width: 301px;
  /* height: 500px; */
}

.calendarMonth {
  text-align: center;
  margin-bottom: 6px;
}

.dayBox {
}

.dayTitle {
  display: flex;
}

.dayTitle > div {
  /* flex:14.2%; */
  /* width: 43px; */
  width: 38px;
  text-align: center;
  margin: 8px 0;
}

.dayContent {
  display: flex;
  flex-wrap: wrap;
}

.dayContent > div {
  /* flex:14.2%; */
  /* width: 43px; */
  width: 38px;
  text-align: center;
  /* margin: 8px 0; */
  padding: 7px 0;
  margin: 2px 0;
  cursor: pointer;
  /* color:  #00A1DE; */
}

.dayContent > div:hover {
  color: #00a1de;
}

/* 对话框 */
.dialogStyle {
  height: 30%;
  background-color: pink;
}

.dialogYearTitle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  padding-top: 20px;
}

.dialogYearContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.dialogYearContentDiv {
  /* width: 300px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 50px;
}

.dialogYearContent .el-button {
  box-sizing: border-box;
  /* padding:6px; */
  padding-top: 12px;
  padding-bottom: 12px;
  width: 25%;
  text-align: center;
  font-size: 17px;
}

/* .dialogYearContent .el-button:hover{
    color: #00A1DE;
    color: #00875A;
    cursor: pointer;
} */

.dialogYearContent .el-button:nth-child(1) {
  padding-left: 20px;
}

.dialogYearContent .el-button:nth-child(2) {
  padding-left: 20px;
}

.dialogYearContent .el-button:nth-child(3) {
  padding-left: 20px;
}

.textColor {
  color: black;
}

/* .dialogYearContent span{
    box-sizing: border-box;
    padding-top:12px;
    padding-bottom:12px;
    width: 25%;
    text-align: center;
    font-size: 17px;
} */

.dialogYearContent span:hover {
  color: #00a1de;
  cursor: pointer;
}

/* /deep/.el-dialog__header{
    padding: 0px;
    padding-bottom: 0px;
}

/deep/ .el-dialog__body{
    padding:0px;
} */

.iHover:hover {
  color: #00a1de;
  cursor: pointer;
}

.yearColor {
  color: #00a1de;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
